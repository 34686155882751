export default {
  'menu.home': 'Αρχικό',
  'menu.dashboard': 'Dashboard',

  'menu.basic': 'Βασική Λειτουργία',
  'menu.basic.read': 'Ανάγνωση δεδομένων',
  'menu.basic.history': 'Δεδομένα Ιστορικού',
  'menu.basic.report': 'Αναφορά περιπολίας',
  'menu.basic.detail': 'Λεπτομερής Αναφορά',
  'menu.basic.chart': 'Στατιστικά διαγράμματα',
  'menu.basic.alarm': 'Δεδομένα συναγερμών',
  'menu.basic.omit': 'Δεδομένα παράλλειψης',
  'menu.basic.raw': 'Αρχικά δεδομένα',
  'menu.basic.calendar': 'Ημερολόγιο Περιπολιών',
  'menu.basic.log': 'Ιστορικό πληροφοριών',
  'menu.basic.realmap': 'Παρακολούθηση σε Πραγματικό χρόνο',
  'menu.basic.attendance': 'Δεδομένα παρουσίας',

  'menu.setup': 'Ρύθμιση Περιπολίας',
  'menu.setup.dept': 'Ρύθμιση Εταιρίας',
  'menu.setup.event': 'Ρύθμιση Συμβάντων',
  'menu.setup.guard': 'Ρύθμιση Φύλακα',
  'menu.setup.site': 'Ρύθμιση σημείων ελέγχου',
  'menu.setup.plan': 'Ρύθμιση Πλάνου',
  'menu.setup.book': 'Ρύθμιση Επαφών',
  'menu.setup.receipt': 'Ρύθμιση αποδεικτικών',
  'menu.setup.down': 'Λήψη δεδομένων',
  'menu.setup.downparam': 'Λήψη δεδομένων',
  'menu.setup.content': 'Ρύθμιση περιεχομένου',
  'menu.setup.voice': 'Ρύθμιση φωνητικών μηνυμάτων',
  'menu.setup.talk': 'Ρύθμιση Walkie-talkie',
  'menu.setup.duty': 'Λήψη δεδομένων παρουσίας',

  'menu.data': 'Συντήρηση δεδομένων',
  'menu.data.device': 'Λίστα συσκευών',
  'menu.data.devices': 'Ρυθμίσεις συσκευής',
  'menu.data.system': 'Ρυθμίσεις συστήματος',
  'menu.data.role': 'Ρυθμίσεις δικαιωμάτων',
  'menu.data.user': 'Ρύθμιση χειριστών',
};
