export default {
  'login.title': 'Bienvenu',
  'login.subtitle': 'Bienvenue sur la plateforme guardtour',
  'login.type.login': 'Connexion',
  'login.type.trial': 'Trial',
  'login.companyCode': "Code de l'entreprise",
  'login.companyCode.required': "Le code d'entreprise est requis",
  'login.userName': "Nom d'utilisateur",
  'login.userName.required': "Nom d'utilisateur est nécessaire",
  'login.password': 'Mot de passe',
  'login.password.required': 'Mot de passe requis',
  'login.remember': 'Se souvenir du mot de passe',
  'login.guest': 'Invité',
  'login.captcha': 'Code de vérification',
  'login.captcha.captchaRequired': 'Code de vérification requis',
  'login.submit': 'Connexion',
  'login.submitapplicaiton': 'Soumettre',
  'login.captcha.required': 'Veuillez terminer la vérification homme-machine',
  'login.trial.companyName': "Nom de l'entreprise",
  'login.trial.companyName.required': "Entrez le nom de l'entreprise",
  'login.trial.equipment.read': 'Lis',
  'login.trial.equipment': "N° de l'appareil ",
  'login.trial.equipment.required': "Lire l'appareil Non.",
  'login.trial.contacts': 'Nom du contact',
  'login.trial.contacts.required': 'Saisir le nom du contact',
  'login.trial.contacts.phone': 'Téléphoner',
  'login.trial.contacts.phone.required': "Téléphone d'entrée",
  'login.trial.captcha': 'Code de vérification',
  'login.trial.captcha.required': "Code de vérification d'entrée",
  'login.trial.captcha.read': 'Envoyer',
  'login.trial.city': 'Ville',
  'login.trial.address.en': 'Adresse',
  'login.trial.countryCode': 'Code postal',
  'login.trial.timeZone': 'Fuseau horaire',
  'login.trial.city.required': "Ville d'entrée",
  'login.trial.address': 'Adresse de la société',
  'login.trial.address.required': "Entrez l'adresse de l'entreprise",
  'login.trial.success': "L'application est réussie",
  'login.trial.contacts.email': 'E-mail',
  'login.trial.contacts.email.required': "E-mail d'entrée",
  'login.trial.alarm.mobile':
    'Le code de vérification a été envoyé sur votre téléphone portable, veuillez le vérifier attentivement',
  'login.trial.alarm.email':
    'Le code de vérification a été envoyé à votre boîte aux lettres, veuillez le vérifier attentivement',
  'login.trail.alarm.email.not.receive': 'Non reçu?',
  'login.trial.alarm.email.spam':
    'Le courrier peut être bloqué par votre fournisseur de messagerie avec une politique de sécurité incorrecte, veuillez le vérifier dans le Spam',
  'login.trial.alarm.reg.mobile':
    'Les informations de compte ont été envoyées sur votre téléphone portable, veuillez les vérifier attentivement',
  'login.trial.alarm.reg.email':
    'Les informations de compte ont été envoyées à votre boîte aux lettres, veuillez les vérifier attentivement',
  'login.trial.captcha.mobile.format':
    'Le format du numéro de téléphone mobile est incorrect. Veuillez saisir à nouveau',
  'login.trial.contacts.email.format':
    'Le format du numéro de boîte aux lettres est incorrect. Veuillez saisir à nouveau',
  'login.trial.contacts.mobile.legal': 'Format de téléphone mobile illégal',
  'login.trial.contacts.email.legal': 'Format de boîte aux lettres illégal',

  'login.app.title': 'Application CloudPatrol+',
  'login.app.subTitle': '「 Gestion de patrouille pure 」',
  'login.app.desc1': 'Scannez pour télécharger',
  'login.app.desc2': "Entrez le code de l'entreprise dans le code du serveur",
  'login.app.desc3': "Utilisez le nom d'utilisateur et le mot de passe pour vous connecter",

  'login.slogan': 'Système de patrouille de sécurité',
  'login.vaptcha.text': "Le plugin est en cours d'initialisation...",

  'login.nocaptcha.loading': 'Chargement...',
  'login.nocaptcha.guide': 'Faites glisser vers la droite pour vérifier',
  'login.nocaptcha.pass': 'Passer',
  'login.nocaptcha.error': 'Oops..., click <a href="javascript:__nc.reset()">here</a> to retry',
  'login.nocaptcha.offline':
    'Network error, click <a href="javascript:__nc.reset()">here</a> to refresh',

  'login.expired.exit.desc': 'Identifiants expirés, veuillez vous reconnecter...',

  'login.companyCode.illegal': "Code d'entreprise illégal",
};
