export default {
  'event.title': '巡检事件',
  'event.btn.add': '增加事件',
  'event.btn.import': '导入事件',
  'event.btn.export': '导出事件',
  'event.table.code': '事件卡号',
  'event.table.name': '事件名称',
  'event.table.simple': '事件卡',
  'event.table.common': '普通事件',
  'event.input.code.placeholder': '请输入事件卡号',
  'event.input.name.placeholder': '请输入事件名称',
};
