import request from '@/utils/request';
import { stringify } from 'qs';
import { getRequestPrefix } from '@/utils/profile';

export async function getMenu() {
  return request(`/${getRequestPrefix()}/menu`);
}

export async function getSysTime() {
  return request(`/api/system/getSystemCurrentDate`);
}

export async function handleData(params) {
  return request(`/api/HandleData?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function template(params) {
  return request(`/api/system/document?${stringify(params)}`);
}
