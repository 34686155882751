export default {
  'book.title': 'Contacts',
  'book.btn.add': 'Add Contacts',
  'book.btn.import': 'Import Contact',
  'book.btn.export': 'Export Contact',
  'book.table.name': 'Contact Name',
  'book.table.tel': 'Contact Telephone Number',
  'book.input.name': 'Please type contact name.',
  'book.input.tel': 'Please type contact telephone number.',
};
