export default {
  'event.title': 'События',
  'event.btn.add': 'Добавить событие',
  'event.btn.import': 'Импорт',
  'event.btn.export': 'Экспорт',
  'event.table.code': 'ID события',
  'event.table.name': 'Название события',
  'event.table.simple': 'Простое событие',
  'event.table.common': 'Общее событие',
  'event.input.code.placeholder': 'Требуется ID метки события.',
  'event.input.name.placeholder': 'Название события является обязательным..',
};
