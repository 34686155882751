export default {
  'exception.fail': '请求失败',
  'exception.unknow': '出现未知问题',
  'exception.network': '网络异常',
  'exception.network.desc': '网络发生异常，无法连接服务器',

  'exception.403': '没有权限可以访问当前资源',
  'exception.404': '无法找到当前所请求的资源',
  'exception.500': '服务器好像遇到了一些问题',

  'exception.400': '发出的请求存在错误',
  'exception.401': '用户凭证失效',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': '网关超时',

  'exception.crash': '我了个去，程序好像出了一些故障...',
};
