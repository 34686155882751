export default {
  'query.condition.dept': 'Company Name',
  'query.condition.guard': 'Guard Name',
  'query.condition.site': 'Checkpoint Name',
  'query.condition.event': 'Event Name',
  'query.condition.plan': 'Plan Name',
  'query.condition.planguard': 'Plan Guard',
  'query.condition.device': 'Device Code',
  'query.condition.begin': 'Begin Time',
  'query.condition.end': 'End Time',
  'query.condition.time': 'Patrol Time',
  'query.condition.alarmtype': 'Alarm Type',
  'query.input.placeholder.site': 'Please select checkpoint',
  'query.input.placeholder.guard': 'Please select guard',
  'query.input.placeholder.device': 'Please select device',
  'query.input.placeholder.plan': 'Please select plan',
  'query.input.placeholder.alarm': 'Please select alarm type',
  'query.history.title': 'History Data',
  'query.history.btn.reanalysis': 'Reanalysis',
  'query.app.remark': 'Content and Notes',
  'query.app.multimedia': 'Multimedia',
  'query.app.view': 'View',
  'query.app.notes': 'Notes',
  'query.app.view.pict': 'View Photos',
  'query.app.view.video': 'View Video',
  'query.app.view.audio': 'View Audio',
  'query.app.options': 'Content Options',
  'query.app.state': 'State',
  'query.app.notentered': 'Not entered',
  'query.app.notselected': 'Not selected',
  'query.app.selected': 'Selected',
  'query.app.site.errspan':
    'The current point error range beyond the specified range, the checkpoint may not be valid checkpoint.',
  'query.app.site.time': 'The current patrol time may have been modified',
  'query.app.site.both':
    'The current point error range beyond the specified range, the checkpoint may not be valid checkpoint, and the current patrol time may have been modified',
  'query.alarm.title': 'Alarm Data',
  'query.alarm.btn.del': 'Delete Data',
  'query.alarm.type.all': 'All Alarms',
  'query.alarm.type.manual': 'Manual Alarm',
  'query.alarm.type.lowvoltage': 'Low-voltage Alarm',
  'query.alarm.type.incline': 'Incline Alarm',
  'query.alarm.type.static': 'Static Alarm',
  'query.alarm.type.impact': 'Impact Alarm',
  'query.alarm.type.open': 'Demolition Alarm',
  'query.alarm.info': 'Alarm Information',
  'query.alarm.time': 'Alarm Time',
  'query.alarm.position': 'Alarm Position',
  'query.omit.title': 'Omission Data',
  'query.omit.rangetime': 'From To',

  'query.chart.title': 'Statistics',
  'query.chart.table.plantimes': 'Plan Times',
  'query.chart.table.arrivedtimes': 'Arrived Times',
  'query.chart.table.omittimes': 'Omission Times',
  'query.chart.table.arrivedrate': 'Arrived Rate',
  'query.chart.table.omitrate': 'Omission Rate',
  'query.chart.table.summary': 'Data Summary',
  'query.chart.tab.data': 'Statistical Data',
  'query.chart.tab.chart': 'Chart Display',
  'query.chart.tab.chart.column': 'Column Charts',
  'query.chart.tab.chart.bar': 'Bar Charts',
  'query.chart.tab.chart.line': 'Line Charts',
  'query.chart.tab.omit': 'Omission Data',
  'query.chart.condition.type': 'Statistics Type',
  'query.chart.condition.dept': 'Statistics as Company',
  'query.chart.condition.guard': 'Statistics as Guard',
  'query.chart.condition.site': 'Statistics as Checkpoint',
  'query.chart.condition.plan': 'Statistics as Plan',

  'query.raw.title': 'Original Data',
  'query.raw.table.card': 'Card ID',
  'query.raw.table.type': 'Card Type',
  'query.raw.table.name': 'Card Name',
  'query.raw.table.unknow': 'Unknown Card',
  'query.raw.table.event': 'Event Card',
  'query.raw.table.guard': 'Guard Card',
  'query.raw.table.site': 'Checkpoint Card',
  'query.raw.table.time': 'Checking Time',
  'query.raw.btn.unknow': 'Unknown tag conversion',
  'query.raw.btn.site': 'Convert to checkpoint',
  'query.raw.btn.guard': 'Convert to guard',
  'query.raw.btn.event': 'Convert to event',

  'query.log.title': 'Log Information',
  'query.log.table.operate': 'Operate Interface',
  'query.log.table.content': 'Operate Content',
  'query.log.table.time': 'Operate Time',
  'query.log.table.user': 'Operator',
  'query.log.form.dept': 'Company Setup',
  'query.log.form.guard': 'Guard Setup',
  'query.log.form.site': 'Checkpoint Setup',
  'query.log.form.plan': 'Plan Setup',
  'query.log.form.read': 'Read Data',

  'query.calendar.january': 'January',
  'query.calendar.february': 'February',
  'query.calendar.march': 'March',
  'query.calendar.april': 'April',
  'query.calendar.may': 'May',
  'query.calendar.june': 'June',
  'query.calendar.july': 'July',
  'query.calendar.august': 'August',
  'query.calendar.september': 'September',
  'query.calendar.october': 'October',
  'query.calendar.november': 'November',
  'query.calendar.december': 'December',
  'query.calendar.year': '-',
  'query.calendar.year1': ' ',
  'query.calendar.month': ' ',

  'query.report.condition.type': 'Data Type',
  'query.report.condition.all': 'All Data',
  'query.report.condition.normal': 'Normal Data',
  'query.report.condition.todo': 'To-do Data',
  'query.report.table.alltimes': 'All Times',
  'query.report.table.patroltimes': 'Patrol Times',
  'query.report.table.topatrol': 'To Patrol Times ',
  'query.report.btn.export.summary': 'Export Summary Report',
  'query.report.btn.export.ordinary': 'Export Ordinary Report',
  'query.report.btn.export.summaryname': 'Report summary data',
  'query.report.btn.export.ordinaryname': 'Report ordinary data',
  'query.report.table.step': 'Step',
  'query.report.btn.del': 'Delete Report Datas',

  'query.history.analysis': 'Confirm to Analysis data?',
  'query.content.fold': 'Collapse All',
  'query.content.expand': 'Expand All',
};
