import request from '@/utils/request';
import { stringify } from 'qs';

export async function loadDepts() {
  return request(`/api/deptController.do?LoadDeptsCombox`);
}

export async function getDeviceList() {
  return request(`/api/device/loadReaders`);
}

export async function getDeviceCheckResult(ReaderCode) {
  return request(`/api/device/getDeviceCheckResult?${stringify(ReaderCode)}`);
}

export async function loadClock() {
  return request(`/api/clock/loadClockDatas`);
}

export async function getMobieGuards(type) {
  return request(`/api/device/getMobileList?${stringify(type)}`);
}

export async function loadCards() {
  return request(`/api/card/loadCard`);
}

// 加载替换巡检宝设备时，加载巡检宝设备列表
export async function getMobileReaders(ReaderCode) {
  return request(`/api/device/loadMobileReaders?${stringify(ReaderCode)}`);
}

export async function addDevice(devicecode) {
  return request(`/api/device/addDevice?ReaderCode=${devicecode}`, {
    method: 'POST',
  });
}

// 手动录入设备号
export async function addDeviceCode(payload) {
  return request(`/api/device/addDeviceCode?${stringify(payload)}`, {
    method: 'POST',
  });
}

export async function setDeviceParam(payload) {
  return request(`/api/device/setParam?${stringify(payload)}`, {
    method: 'POST',
  });
}

export async function activeDevice(params) {
  return request(`/api/device/active?${stringify(params)}`, {
    method: 'PUT',
  });
}

export async function updateExtraData(params) {
  return request(`/api/device/updateExtra?${stringify(params)}`, {
    method: 'PUT',
  });
}

export async function replaceDevice(params) {
  return request(`/api/device/replace?${stringify(params)}`, {
    method: 'PUT',
  });
}

export async function delDevice(params) {
  return request(`/api/device/del?${stringify(params)}`, {
    method: 'DELETE',
  });
}

export async function addClock(params) {
  return request(`/api/clock/add?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function editClock(params) {
  return request(`/api/clock/edit?${stringify(params)}`, {
    method: 'PUT',
    body: params,
  });
}

export async function delClock(params) {
  return request(`/api/clock/delete?ClockID=${params}`, {
    method: 'DELETE',
  });
}

export async function loadSound() {
  return request(`/api/sounds`, {
    method: 'GET',
  });
}

export async function addSound(params) {
  return request(`/api/sounds/add?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function uptState() {
  return request(`/api/sounds/state`, {
    method: 'POST',
  });
}

export async function updateSound(params) {
  return request(`/api/sound?${stringify(params)}`, {
    method: 'PUT',
    body: params,
  });
}

// 巡检宝设备替换
export async function replaceApp(params) {
  return request(`/api/device/replaceApp?${stringify(params)}`, {
    method: 'PUT',
    body: params,
  });
}

// 修改巡检宝登录密码
export async function initPwd(params) {
  return request(`/api/device/uptAppPwd?${stringify(params)}`, {
    method: 'PUT',
    body: params,
  });
}

export async function delSound(params) {
  return request(`/api/sounds/delete?SoundID=${params}`, {
    method: 'DELETE',
  });
}

export async function setsysparam(params) {
  return request(`/api/system/sysSet?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function getsysparam() {
  return request(`/api/system/loadSysSet`, {
    method: 'GET',
  });
}

export async function getdbparam() {
  return request(`/api/system/loadDbSet`, {
    method: 'GET',
  });
}

export async function getMapDevices() {
  return request(`/api/device/map`, {
    method: 'GET',
  });
}

export async function delallfinger(params) {
  return request(`/api/finger/delReaderFinger?${stringify(params)}`, {
    method: 'DELETE',
  });
}

export async function saveReaderEvent(params) {
  return request(`/api/system/saveReaderEvent?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function saveReaderLog(params) {
  return request(`/api/system/recordLog?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function getLastBackTime() {
  return request(`/api/backupFiles/lastBackTime`, {
    method: 'GET',
  });
}
