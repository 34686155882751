export default {
  'system.title': '系统参数设置',
  'system.event': '事件功能',
  'system.event.des': '是否支持事件功能',
  'system.plan': '按次计划',
  'system.plan.des': '能够周期性执行的计划，例如每天、每周、每月执行',
  'system.patroltime': '巡检用时',
  'system.patroltime.des': '是否在巡检报告中显示每圈计划巡检总时间',
  'system.remaintime': '停留时间',
  'system.remaintime.des': '是否在巡检报告中显示每个点的停留时间',
  'system.Sequence': '顺序巡检',
  'system.Sequence.des': '是否指定计划内的每个巡检点必须按照计划中设置的顺序进行巡检',
  'system.timeformate': '日期格式',
  'system.timeformate.des': '系统显示的日期格式',
  'system.dst': '夏令时',
  'system.dst.des': '是否启用夏令时，更改后计划数据相关时间将从次日起生效',
  'system.database.period': '数据库备份周期',
  'system.database.period.des': '多久对数据库进行备份一次，单位：天',
  'system.database.time': '数据库备份时间',
  'system.database.time.des': '请尽量选择在数据上传低峰时间',
  'system.database.path': '数据库备份路径',
  'system.database.path.des': '请输入数据库备份路径，例如：D:\\backup，路径为空则代表不备份数据库',
  'system.save': '保存设置',
  'system.enable': '启用',
  'system.disable': '关闭',
  'system.notification.prompt': '提示',
  'system.notification.success': '保存成功！',
  'system.back.title': '数据库备份',
  'system.back.desc': '畚份系统数据或将系统数据恢复到某一时间点',
  'system.back.lastBackTime': '上次备份时间：',
  'system.back.managementBack': '管理备份',
};
