export default {
  'exception.fail': 'Request Failed',
  'exception.unknow': 'Unknown error occurs',
  'exception.network': 'Network Error',
  'exception.network.desc': 'Cannot connect to the server',

  'exception.403': 'Sorry, you are not authorized to access this resource.',
  'exception.404': 'Oops..., the resource can not be found.',
  'exception.500': 'Orz, the server may encountered a little troubles.',

  'exception.400': 'Bad Request',
  'exception.401': 'Invalid User Credentials',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': 'Gateway Timeout',

  'exception.crash': 'Ooops, it seems like that we have encountered something unexpected...',
};
