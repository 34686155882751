/* eslint-disable no-restricted-globals */
import { parse } from 'querystring';
import localeZhCn from 'antd/lib/date-picker/locale/zh_CN';
import localeEnUs from 'antd/lib/date-picker/locale/en_US';
import localeZhTra from 'antd/lib/date-picker/locale/zh_TW';
import { getLocale, history } from 'umi';
// import moment from 'moment';
import i18n from './i18n';

let deptidArr = [];
export const getDatePickerLocale = () => {
  if (getLocale().startsWith('zh')) {
    if (getLocale().includes('HK') || getLocale().includes('TW')) {
      return localeZhTra;
    }
    return localeZhCn;
  }
  return localeEnUs;
};

// 将10进制转换为四位16进制，自动补零；
export const to4hex = (i) => {
  return (i + 0x10000).toString(16).substr(-4).toUpperCase();
};

// 生成随机KEY 用于某些循环构建元素控件场景key重复时使用
export const getKey = () => {
  function S4() {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  }
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}`;
};

const reg =
  // eslint-disable-next-line no-useless-escape
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);

export const isDevEnv = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV === 'development';
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const getCPU = (agent) => {
  if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) return 'x64';
  return 'x32';
};

export const getClientOS = (sUserAgent) => {
  const isWin = navigator.platform === 'Win32' || navigator.platform === 'Windows';
  if (isWin) {
    const isWin2K =
      sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
    if (isWin2K) {
      return '1.0';
    }
    const isWinXP =
      sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
    if (isWinXP) {
      return '1.0';
    }
    const isWin2003 =
      sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
    if (isWin2003) {
      return '1.0';
    }
    return '1.1';
  }
  return 'other';
};

const setDouble = (value) => {
  let newValue = value;
  if (value < 10) {
    newValue = `0${value}`;
  }
  return newValue;
};
export const ReturnHome = () => {
  history.push('../dashboard');
};

export const SecondToDate = (msd) => {
  if (msd === 0 || msd === undefined) {
    return '0:00:00';
  }
  let time = msd;
  if (time !== null && time !== '') {
    if (time > 60 && time < 60 * 60) {
      time = `0:${setDouble(parseInt((time / 60.0).toString(), 10))}:${setDouble(
        Math.round((parseFloat(time / 60.0) - parseInt((time / 60.0).toString(), 10)) * 60),
      )}`;
    } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
      time = `${setDouble(parseInt((time / 3600.0).toString(), 10))}:${setDouble(
        parseInt(
          ((parseFloat(time / 3600.0) - parseInt((time / 3600.0).toString(), 10)) * 60).toString(),
          10,
        ),
      )}:${setDouble(
        Math.round(
          (parseFloat((parseFloat(time / 3600.0) - parseInt((time / 3600.0).toString(), 10)) * 60) -
            parseInt(
              (
                (parseFloat(time / 3600.0) - parseInt((time / 3600.0).toString(), 10)) *
                60
              ).toString(),
              10,
            )) *
            60,
        ),
      )}`;
    } else if (time >= 60 * 60 * 24) {
      // 超过一天
      const days = parseInt((time / 86400.0).toString(), 10);
      const time1 = parseInt((time - 86400 * days).toString(), 10);
      const hours = setDouble(parseInt((time1 / 3600.0).toString(), 10));
      const minutes = setDouble(
        Math.round(
          parseFloat((parseFloat(time1 / 3600.0) - parseInt((time1 / 3600.0).toString(), 10)) * 60),
        ),
      );
      const seconds = setDouble(
        Math.round(
          (parseFloat(
            (parseFloat(time1 / 3600.0) - parseInt((time1 / 3600.0).toString(), 10)) * 60,
          ) -
            parseInt(
              (
                (parseFloat(time1 / 3600.0) - parseInt((time1 / 3600.0).toString(), 10)) *
                60
              ).toString(),
              10,
            )) *
            60,
        ),
      );
      time = `${days}${i18n('device.table.card.date')} ${hours}:${minutes}:${seconds}`;
    } else {
      time = `0:00:${setDouble(parseInt(time.toString(), 10))}`;
    }
  }
  return time;
};

export const parseUrlParams = () => {
  const url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  const regUrl = /^[^\?]+\?([\w\W]+)$/;
  const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g;
  const arrUrl = regUrl.exec(url);
  const ret = {};
  if (arrUrl && arrUrl[1]) {
    const strPara = arrUrl[1];
    let result;
    // eslint-disable-next-line no-cond-assign
    while ((result = regPara.exec(strPara)) != null) {
      // eslint-disable-next-line prefer-destructuring
      ret[result[1]] = result[2];
    }
  }
  return ret;
};

export const getBrowserLan = () => {
  const lan = (navigator.language || navigator.browserLanguage).toLowerCase();
  if (lan.indexOf('zh') >= 0) {
    return true;
  }
  return false;
};

export const loopTreeList = (array, flag) => {
  if (flag === 0) deptidArr = [];

  Object.keys(array).forEach((i) => {
    deptidArr.push(array[i].key);
    if (array[i].children) {
      loopTreeList(array[i].children);
    } else {
      deptidArr.push(array[i].key, 1);
    }
  });
  return deptidArr;
};

export const getUrlParam = (name) => {
  // eslint-disable-next-line prefer-template
  const regExp = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substring(1).match(regExp);
  if (r != null) {
    return decodeURI(r[2]);
  }
  return null;
};

export const isLargestScreen = () => {
  return (
    window.outerHeight >= window.screen.availHeight && window.outerWidth >= window.screen.availWidth
  );
};

export const zoomToLargestScreen = () => {
  window.moveTo(0, 0);
  window.resizeTo(window.screen.availWidth, window.screen.availHeight);
};

// TODO 限制查询日期范围（只能查询2个月的数据），非码云软件此处可以适当放宽
// eslint-disable-next-line no-unused-vars
export const restrictDate = (bDate, eDate) => {
  // return bDate && bDate < moment(eDate).add(-60, 'days');
  return false;
};

// 收费设备列表
export const chargeDevices = () => {
  const chargeDevice = [
    '5000F3',
    '5000L6',
    '5000P6',
    '5000K',
    '5000P3+',
    '5000P5+',
    '5000P4',
    '5000P4D',
    '5000ZG',
    '5000Mobile',
  ];
  return chargeDevice;
};

// 设置软件版本：码云(cloud)、M1.0、7.0、7.5、3.0
export const getSoftwareVersion = () => {
  return 'M1.0';
};

// 判断软件是否中性显示（Y：有标，其他：无标），有标无标主要针对：7.0、7.5、3.0软件，M1.0一律中性显示
export const getSoftwareModel = () => {
  return 'N';
};

// 获取浏览器IP及端口号
export const getIpAddress = () => {
  // eslint-disable-next-line prefer-destructuring
  const port = location.port;
  let address = `${top.location.hostname}:${port}`;
  if (port === '80' || port === '') {
    address = top.location.hostname;
  }
  return address;
};

export const addOverseaOnlineChatWidget = () => {
  const tawk = document.getElementById('tawk');
  if (tawk) {
    window.Tawk_API?.showWidget();
  } else {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.id = 'tawk';
    s1.async = true;
    s1.src = 'https://embed.tawk.to/616eabdff7c0440a591ef46a/1fic4up37';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }
};

export const removeOverseaOnlineChatWidget = () => {
  window.Tawk_API?.hideWidget();
};

export const timeZoneList = [
  {
    value: '-12:00',
    label: 'UTC-12:00',
  },
  {
    value: '-11:00',
    label: 'UTC-11:00',
  },
  {
    value: '-10:00',
    label: 'UTC-10:00',
  },
  {
    value: '-09:50',
    label: 'UTC-09:30',
  },
  {
    value: '-09:00',
    label: 'UTC-09:00',
  },
  {
    value: '-08:00',
    label: 'UTC-08:00',
  },
  {
    value: '-07:00',
    label: 'UTC-07:00',
  },
  {
    value: '-06:00',
    label: 'UTC-06:00',
  },
  {
    value: '-05:00',
    label: 'UTC-05:00',
  },
  {
    value: '-04:50',
    label: 'UTC-04:30',
  },
  {
    value: '-04:00',
    label: 'UTC-04:00',
  },
  {
    value: '-03:50',
    label: 'UTC-03:30',
  },
  {
    value: '-03:00',
    label: 'UTC-03:00',
  },
  {
    value: '-02:00',
    label: 'UTC-02:00',
  },
  {
    value: '-01:00',
    label: 'UTC-01:00',
  },
  {
    value: '0:00',
    label: 'UTC 0:00',
  },
  {
    value: '01:00',
    label: 'UTC+01:00',
  },
  {
    value: '02:00',
    label: 'UTC+02:00',
  },
  {
    value: '03:00',
    label: 'UTC+03:00',
  },
  {
    value: '03:50',
    label: 'UTC+03:30',
  },
  {
    value: '04:00',
    label: 'UTC+04:00',
  },
  {
    value: '05:00',
    label: 'UTC+05:00',
  },
  {
    value: '05:50',
    label: 'UTC+05:30',
  },
  {
    value: '05:45',
    label: 'UTC+05:45',
  },
  {
    value: '06:00',
    label: 'UTC+06:00',
  },
  {
    value: '06:50',
    label: 'UTC+06:30',
  },
  {
    value: '07:00',
    label: 'UTC+07:00',
  },
  {
    value: '08:00',
    label: 'UTC+08:00',
  },
  {
    value: '09:00',
    label: 'UTC+09:00',
  },
  {
    value: '09:50',
    label: 'UTC+09:30',
  },
  {
    value: '10:00',
    label: 'UTC+10:00',
  },
  {
    value: '11:00',
    label: 'UTC+11:00',
  },
  {
    value: '11:50',
    label: 'UTC+11:30',
  },
  {
    value: '12:00',
    label: 'UTC+12:00',
  },
];

export const zebraRow = (record, index) => {
  let classname = 'light-row';
  if (index % 2 === 1) classname = 'dark-row';
  return classname;
};

export const getProTreeSelectFieldProps = (treeList) => {
  return {
    showArrow: true,
    dropdownMatchSelectWidth: false,
    showSearch: false,
    dropdownStyle: { maxHeight: '500px' },
    treeCheckable: true,
    maxTagCount: 2,
    fieldNames: {
      label: 'title',
    },
    options: treeList,
    showCheckedStrategy: 'SHOW_ALL',
  };
};

// 是否注册GPS设备
export const isRegvGpsDevice = () => {
  const regvDevice = localStorage.getItem('deviceType');
  if (regvDevice === null || regvDevice === '') {
    return 1;
  }
  if (
    regvDevice.indexOf('5000P3+') !== -1 ||
    regvDevice.indexOf('5000P5+') !== -1 ||
    regvDevice.indexOf('5000P4') !== -1 ||
    regvDevice.indexOf('5000P4D') !== -1 ||
    regvDevice.indexOf('5000ZG') !== -1 ||
    regvDevice.indexOf('5000P6') !== -1 ||
    regvDevice.indexOf('5000Mobile') !== -1
  ) {
    return 1;
  }
  return 0;
};

// 证件类型
export const idCardTypeValList = () => {
  return ['身份证', '户口本', '其他'].map((text) => ({
    label: text,
    value: text,
  }));
};

// 民族
export const nationalityValList = () => {
  return [
    '汉族',
    '满族',
    '蒙古族',
    '回族',
    '藏族',
    '维吾尔族',
    '苗族',
    '彝族',
    '壮族',
    '布依族',
    '侗族',
    '瑶族',
    '白族',
    '土家族',
    '哈尼族',
    '哈萨克族',
    '傣族',
    '黎族',
    '傈僳族',
    '佤族',
    '畲族',
    '高山族',
    '拉祜族',
    '水族',
    '东乡族',
    '纳西族',
    '景颇族',
    '柯尔克孜族',
    '土族',
    '达斡尔族',
    '仫佬族',
    '羌族',
    '布朗族',
    '撒拉族',
    '毛南族',
    '仡佬族',
    '锡伯族',
    '阿昌族',
    '普米族',
    '朝鲜族',
    '塔吉克族',
    '怒族',
    '乌孜别克族',
    '俄罗斯族',
    '鄂温克族',
    '德昂族',
    '保安族',
    '裕固族',
    '京族',
    '塔塔尔族',
    '独龙族',
    '鄂伦春族',
    '赫哲族',
    '门巴族',
    '珞巴族',
    '基诺族',
    '其他',
  ].map((text) => ({
    label: text,
    value: text,
  }));
};

// 文化水平
export const culturalLevelValList = () => {
  return [
    '文盲或未受过正规教育',
    '小学文化',
    '初中文化',
    '高中文化',
    '大学本科文化',
    '研究生及以上文化',
  ].map((text) => ({
    label: text,
    value: text,
  }));
};

// 性别
export const sexValList = () => {
  return ['男', '女', '未知'].map((text) => ({
    label: text,
    value: text,
  }));
};

// 省份
export const provinceValList = async () => {
  const address = await (await import('@/assets/address')).default;
  return address.map((item) => ({
    label: item.label,
    value: item.label,
  }));
};

// 市区
export const cityValList = async (provinceVal) => {
  const address = await (await import('@/assets/address')).default;
  const selectProvince = address.filter((item) => item.label === provinceVal);
  const valList = [];
  if (selectProvince && selectProvince.length) {
    const cityList = selectProvince[0].children;
    if (cityList && cityList.length) {
      cityList.map((item) =>
        valList.push({
          label: item.label,
          value: item.label,
        }),
      );
    }
  }
  return valList;
};

// 县城
export const CountyValList = async (provinceVal, cityVal) => {
  const address = await (await import('@/assets/address')).default;
  const selectProvince = address.filter((item) => item.label === provinceVal);
  const valList = [];
  if (selectProvince && selectProvince.length) {
    const cityList = selectProvince[0].children;
    if (cityList && cityList.length) {
      const selectCity = cityList.filter((item) => item.label === cityVal);
      if (selectCity && selectCity.length) {
        const countryList = selectCity[0].children;
        if (countryList && countryList.length) {
          countryList.map((item) =>
            valList.push({
              label: item.label,
              value: item.label,
            }),
          );
        }
      }
    }
  }
  return valList;
};

// 主要职务
export const positionValList = () => {
  return ['治安责任人员', '治安服务人员', '治安监督人员', '授权管理人员', '业务绑定人员'].map(
    (text) => ({
      label: text,
      value: text,
    }),
  );
};

// 服务内容
export const serviceContentValList = () => {
  return ['治安责任人员', '治安服务人员', '治安监督人员', '授权管理人员', '业务绑定人员'].map(
    (text) => ({
      label: text,
      value: text,
    }),
  );
};

// 在职状态
export const onJobValList = () => {
  return ['在职', '不在职'].map((text) => ({
    label: text,
    value: text,
  }));
};
