export default {
  'map.alarmcontentinfo.alarmdetail': 'Сведения о тревоге:',
  'map.alarmcontentinfo.unkonwdetail': 'Неизвестные подробности',
  'map.alarmcontentinfo.iknow': 'В обработке',

  'map.devicecontentinfo.unkonwperson': 'Неизвестный человек',
  'map.devicecontentinfo.track.timerange': 'Диапазон времени воспроизведения данных трека',
  'map.devicecontentinfo.track.speed': 'Скорость воспроизведения(km/h)',
  'map.devicecontentinfo.track.begin': 'Начать воспроизведение',

  'map.floatingtoolbar.track.replay': 'Повторить',
  'map.floatingtoolbar.track.pause': 'Пауза',
  'map.floatingtoolbar.track.resume': 'продолжить',
  'map.floatingtoolbar.track.clear': 'Очистить трек',
  'map.floatingtoolbar.track.resetlimit': 'Условие сброса',

  'map.device.deviceno': 'Номер устройства:',
  'map.search.nocondition.msg': 'Пожалуйста, введите условия поиска! ',
  'map.track.notime.msg': 'Пожалуйста, выберите диапазон времени воспроизведения трека! ',
  'map.track.empty': 'Нет действительных данных трека! ',
  'map.site.sitename': 'имя сайта',
  'map.site.address': 'Адрес',
  'map.module.title': 'Мониторинг в реальном времени',
  'map.sitecontentinfon.unknowdept': 'Неизвестная область',
  'map.sitecontentinfon.unknowperson': 'Неизвестный человек',
  'map.sitecontentinfon.actionhistory': 'Просмотреть историю',
  'map.topcomponent.select.plan': 'Выберите план',
  'map.topcomponent.device.onlinetime': 'Время онлайн-устройства (единица измерения: минуты)',
  'map.topcomponent.screen.full': 'Включить полноэкранный режим',

  'map.realdata.table.deptname': 'имя региона',
  'map.realdata.table.sitename': 'имя сайта',
  'map.realdata.table.guardname': 'Сотрудник',
  'map.realdata.table.guardcode': 'номер устройства',
  'map.realdata.table.happentime': 'Время патрулирования',

  'map.realdata.changepage.old': 'Переключиться на старую страницу карты',
  'map.realdata.changepage.new': 'Переключиться на новую страницу карты',
  'map.realdata': 'Реальные данные',

  'map.google.normal': 'нормальный',
  'map.google.satellite': 'Спутник',

  'map.videomodal.title': 'видео',
  'map.audiomodal.title': 'аудио',
  'карта.кнопка.закрыть': 'закрыть',

  'map.playback': 'Отслеживать воспроизведение',
  'map.playback.over': 'Воспроизведение трека окончено, этот контрольный пробег:',
  'map.playback.mile': 'метр',
  'map.playback.timespan':
    'Период воспроизведения трека не может превышать 2 дня, выберите диапазон дат еще раз',

  'map.load.timeout': 'Карта загружается неправильно, страницу необходимо перезагрузить',

  'map.cluster.more': 'Чтобы увидеть больше координат, увеличьте карту',
  'map.site.add.confirm':
    'Вы уверены, что хотите добавить на карту все точки текущего выбранного плана? ',
  'map.site.del.confirm':
    'Вы уверены, что хотите удалить с карты все точки текущего выбранного плана? ',
  'map.site.del.info': 'Пожалуйста, выберите план для работы',
};
