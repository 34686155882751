export default {
  'exception.fail': '請求失敗',
  'exception.unknow': '出現未知問題',
  'exception.network': '網絡異常',
  'exception.network.desc': '網絡發生異常，無法連接服務器',

  'exception.403': '沒有權限可以訪問當前資源',
  'exception.404': '無法找到當前所請求的資源',
  'exception.500': '服務器好像遇到了一些問題',

  'exception.400': '發出的請求存在錯誤',
  'exception.401': '用戶憑證失效',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': '網關超時',

  'exception.crash': 'Ooops，程式好像出了點故障...',
};
